import React from 'react';
import Carousel from "./Carousel";
import { NavLink } from 'react-router-dom';

const Home = () => {

    return (
    
        <div className="bodyContainer">
            <h1>About Me</h1> 
            <hr />
            <article style={{marginLeft:"auto", marginRight:"auto", width:"65%", textAlign:"left"}}>
                <p>
                    Hi and welcome to my portfolio! My name is Alejandro De Paz, and I was born and raised in Portland, Oregon,
                    where I've spent the last 22 years playing pick-up games of basketball, biking around the city with friends, 
                    perfecting my tennis serve, and indulging in endless foodcart cuisine. When I'm not eating good food or staying 
                    active outdoors, I like to focus on everything from mastering my music production skills with Ableton to playing my 
                    favorite videogames (currently Super Smash Bros. Melee and Overwatch), and even exercising my own creative gastronomy 
                    by cooking diverse cuisine like homemade Vietnamese Bánh mì and Salvadoran Pupusas. 
                </p>
                <div>
                    <Carousel />
                </div>
                <p>
                    I recently graduated from the University of Oregon, where I received my 
                    B.S. in Computer & Information Science with minors in Mathematics and Computer Information
                    Technology (CIT). In the past year, I've had the honor of working as a Data Science Intern with Weir 
                    ESCO, and as a Website Programmer with University of Oregon Continuing and Professional
                    Education. Between these two roles, I've built everything from responsive frontend components, to
                    large scale custom algorithms. Both positions were truly invaluable, providing me with meaningful 
                    hands-on experience that has helped shape my software engineering expertise. 
                </p>
                <p>
                    While I'd like to consider myself technology agnostic, suffice it to say that I'm most proficient 
                    with Python, JavaScript, and C. I have strong knowledge of fundamental data structures and algorithms, 
                    and I feel confident in my ability to apply these concepts and techniques in practical ways. 
                    Above all else, I'm a motived self-starter who's eager to learn and grow; I've engaged in numerous
                    personal projects encompassing everything from fullstack web applications to small start-up ventures,
                    and I have a proven history of learning on-the-fly and working effectively on interdisciplinary teams. 
                </p>
                <p>
                    I'm currently looking for a full-time software engineering role, or internship, where I can leverage my diverse skillset
                    to help develop enterprise level solutions at a mid-to-large sized company. If you feel like I could be a good
                    fit for your team and mission, please don't hesitate to <NavLink exact to="/contact" className="nav_link" activeClassName="current"><em>reach out!</em></NavLink>
                </p>
            </article>
        </div>
    )
}

export default Home;