import React from "react";

class ErrorBoundary extends React.Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    // You can also log the error to an error reporting service
    console.info(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return (
        <div>
          <h1>Oops!</h1>;
          <hr style={{ width: "75%" }} />
          <article
            style={{
              marginLeft: "auto",
              marginRight: "auto",
              width: "65%",
              textAlign: "left",
            }}
          >
            <p>
              It looks like there was a problem loading your request. Sorry
              about that!
            </p>
          </article>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
