import React, { useState, useEffect } from 'react';
import Button from '@material-ui/core/Button';
import TextField from '@material-ui/core/TextField';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { makeStyles } from '@material-ui/core/styles';

import { Post, PostStatus } from "../models";
import { DataStore } from "@aws-amplify/datastore";
import { Storage } from "aws-amplify";

const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
    title: {
        fontSize: 14,
    }
});

const PostEditModal = ({post, open, handleClose, fetchPosts}) => {

    const [postToUpdate, editPost] = useState(post);
    const [imgInfo, updateImg] = useState(null);
    const classes = useStyles();

    useEffect(() => {
        editPost(post);
    }, [post]);

    const handleChange = (e) => {

        const name = e.target.name;
        const value = e.target.value; 
        editPost({...postToUpdate, [name]:value})
    }

    const handleImageUpload = (e) =>{

        const file = e.target.files[0];
        const filename = e.target.value.split("\\").pop()
        updateImg({"name":filename, "file": file});

    }

    async function updatePost(publishBool){

        if (imgInfo != null){
            var { url, key } = await storeImage();
    
        } else{
            url = postToUpdate.imgKey;
            key = postToUpdate.imgUrl;
        }
        
        if (postToUpdate != null){
            
            var status = publishBool ? PostStatus.PUBLISHED : PostStatus.DRAFT;

            await DataStore.save(Post.copyOf(post, updated => {
                updated.title = postToUpdate.title;
                updated.content = postToUpdate.content;
                updated.imgKey = key;
                updated.imgUrl = url;
                updated.status = status
            }))
            .then(fetchPosts())
            .catch(err =>{
                alert("Failed to Update Post!");
                console.info(err);
            })
        } else{
    
            alert("Couldn't Find Post to Update!")
        }
    }

    async function storeImage() {

        var url = "";
        var key = imgInfo.name;

        await Storage.put(imgInfo.name, imgInfo.file, { // store image in S3 bucket, which returns the stored image's key
            contentType: 'image/*'
        })
        .then (result => Storage.get(result.key)) // retrieve publicly accessible URL for image, and update state to hold this URL
        .then (imgUrl => url = "https://blogimages190215-backend.s3.us-east-2.amazonaws.com/public/" + key) 
        .catch(err => {
            alert("Failed to Store Image!")
            console.info(err)
        });

        return {url, key}

    }

    return (
    <div>
        <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title">
        <DialogTitle id="form-dialog-title">Edit Post</DialogTitle>
        <DialogContent>
            <DialogContentText>
            Edit the content below to update this post.
            </DialogContentText>
            <form className={classes.root} noValidate autoComplete="off" style={{margin:"0 auto"}}>
                <TextField required id="standard-basic" label="Title" name="title" value={postToUpdate.title} onChange={e => handleChange(e)}/>
                <TextField style={{marginTop:"10px"}} label="Content" multiline rows="10" fullWidth variant="outlined" name="content" value={postToUpdate.content} onChange={e => handleChange(e)}/>
                <input accept="image/*" id="icon-button-file" type="file" onChange={(evt) => handleImageUpload(evt)} />
                <label htmlFor="icon-button-file">
                    <IconButton color="primary" aria-label="upload picture" component="span">
                    <PhotoCamera />
                    </IconButton>
                </label>
            </form>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                handleClose(); // lift state up
                updatePost(false);
            }} color="primary">
            Save
            </Button>
            <Button onClick={() =>{
                handleClose(); //lift state up
                updatePost(true);
            }} color="primary">
            Update
            </Button>
        </DialogActions>
        </Dialog>
    </div>
    );
}

export default PostEditModal;