import React, { useState, useEffect } from 'react';
import { AmplifyAuthenticator, AmplifySignOut, AmplifySignIn } from '@aws-amplify/ui-react';
import { AuthState, onAuthUIStateChange } from '@aws-amplify/ui-components';
import BlogEditor from "./BlogEditor";

const LoginForm = () => {
    const [authState, setAuthState] = useState();
    const [user, setUser] = useState();

    useEffect(() => {
        return onAuthUIStateChange((nextAuthState, authData) => {
            setAuthState(nextAuthState);
            setUser(authData)
        });
    }, []);

  return authState === AuthState.SignedIn && user ? (
        <div>
            <AmplifySignOut />
            <BlogEditor />
        </div>

    ) : (
    <div style={{paddingTop:"5vh"}}>
        <AmplifyAuthenticator>
            <AmplifySignIn headerText="Please Sign In" slot="sign-in" hideSignUp="true"></AmplifySignIn>
        </AmplifyAuthenticator>
    </div>
  );
}

export default LoginForm;