import React, { useState, useEffect } from 'react';
import { DataStore } from "@aws-amplify/datastore";
import { Storage } from "aws-amplify";
import PostEditModal from "./Modal";

import { Post, PostStatus } from "../models";
import { Card, CardActionArea, TextField, CardContent, Typography, CardActions, Button } from "@material-ui/core";
import { makeStyles } from '@material-ui/core/styles';
import IconButton from '@material-ui/core/IconButton';
import PhotoCamera from '@material-ui/icons/PhotoCamera';
import { v4 as uuidv4 } from 'uuid';

const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
    title: {
        fontSize: 14,
    }
});


// START BLOG EDITOR COMPONENT 
const BlogEditor = () => {

    // set initial state of a post. It does not yet have any title, content, or id. Status is draft. 
    const initialState = {id:"", title:"", status:PostStatus.DRAFT,  content:"", date:"", imgUrl:"#", imgKey:""};
    const classes = useStyles();

    const [postToUpdate, setPostToUpdate] = useState(initialState)
    const [posts, updatePosts] = useState([]);
    const [formState, updateForm] = useState(initialState);
    const [imgInfo, updateImg] = useState(null);

    const [open, setOpen] = useState(false);

    useEffect( () =>{

        fetchPosts(); // on component load, fetch posts and update state. 
        const subscription = DataStore.observe(Post).subscribe(() =>{
            fetchPosts()
        }); // makes it so that DataStore calls this callback function when an instance of Post changes in the backend
        return () => subscription.unsubscribe()
    }, [])

    const handleClose = () => {
        setOpen(false);
    };

    const handleChange = (e) => {

        const name = e.target.name;
        const value = e.target.value; 
        updateForm({...formState, [name]:value})
    }

    // BEGIN GRAPHQL HELPHER FUNCTIONS
    const handleImageUpload = (e) =>{

        const file = e.target.files[0];
        const filename = e.target.value.split("\\").pop()
        updateImg({"name":filename, "file": file});

    }

    async function storeImage() {

        var url = "";
        var key = imgInfo.name;

        await Storage.put(imgInfo.name, imgInfo.file, { // store image in S3 bucket, which returns the stored image's key
            contentType: 'image/*'
        })
        .then (result => Storage.get(result.key)) // retrieve publicly accessible URL for image, and update state to hold this URL
        .then (imgUrl => url = "https://blogimages190215-backend.s3.us-east-2.amazonaws.com/public/" + key) 
        .catch(err => {
            alert("Failed to Store Image!")
            console.info(err)
        });

        return {url, key}

    }

    async function fetchPosts(){

        const posts = await DataStore.query(Post).catch(err => {
            console.info(err);
            alert("Couldn't Fetch Posts!");
        });
        updatePosts(posts);
    }

    async function editPost(id){

        const post = await DataStore.query(Post, id).catch(err => { // find the post in DynamoDB by id
            console.info(err);
            alert("Sorry, But We Couldn't Find The Post You're Looking For!")
        });
        if (post !== null){

            setPostToUpdate(post)
            setOpen(true);
        }
    }

    async function createPost(publishBool){

        if (!formState.title){
            alert("Your post must include a title!")
            return 
        } else{
            var post;
            const postID = uuidv4();
            var date = new Date();
            var dateStr = date.toLocaleDateString();

            if (imgInfo !== null){
                var { url, key } = await storeImage();

            } else{
                url = "";
                key = "";
            }

            if (publishBool){
                post = new Post({...formState, id:postID, status:PostStatus.PUBLISHED, date:dateStr, imgUrl:url, imgKey:key})
            }else {
                post = new Post({...formState, id:postID, date:dateStr, imgUrl:url, imgKey:key}) // leave status as DRAFT
            }

            await DataStore.save(post).catch(err => console.info(err));
            updateForm(initialState); // reset form to be empty
        }
    }

    async function deletePost(id){

        const postToDelete = await DataStore.query(Post, id).catch(err => console.info(err))
        if (postToDelete != null){
            DataStore.delete(postToDelete).catch(err => {
                alert("Failed to Delete Post.")
                console.info(err);
            }) // delete blog post from DynamoDB
            .then(Storage.remove(postToDelete.imgKey).catch(err => {
                alert("Failed to Delete Image from S3.") // remove image from S3
                console.info(err)
            }))
            .then(fetchPosts())
            .catch(err => console.info(err))
        } else{

            alert("Sorry, we couldn't find that post to delete!")
        }
        
    }

    // END HELPER FUNCTIONS 

    return (

        <div>
            <h1 style={{color:"blue"}}>Welcome, Alejandro.</h1>
            <hr style={{width:"65%"}}/>
            <p>You're current blog postings are listed below.</p>
            <div id="blogPostings">
                {posts.map(post => (

                    <Card className={classes.root} key={post.id} style={{margin:"0 auto", marginTop:"15px"}}>
                    <CardActionArea>
                        <CardContent>
                        <Typography className={classes.title} color="textSecondary" gutterBottom>
                            {post.date}
                        </Typography>
                            <Typography gutterBottom variant="h5" component="h2">
                                {post.title}
                            </Typography>
                            <Typography variant="body2" color="textSecondary" component="p">
                                {post.content.substring(3,100) + "..."}
                            </Typography>
                        </CardContent>
                    </CardActionArea>
                    <CardActions>
                        <Button size="small" color="primary" onClick={()=>{editPost(post.id)}}>Edit</Button> &nbsp; <Button size="small" color="primary" onClick={() => deletePost(post.id)}>Delete</Button>
                    </CardActions>
                    </Card>

                ))}
                <PostEditModal post={postToUpdate} handleClose={handleClose} open={open} fetchPosts={fetchPosts}/>
            </div>
            <br /> <br />
            <div id="newPost">
                <h2>Make A New Blog Post: </h2>
                <form className={classes.root} noValidate autoComplete="off" style={{margin:"0 auto"}}>
                    <TextField required id="standard-basic" label="Title" name="title" value={formState.title} onChange={e => handleChange(e)}/>
                    <TextField style={{marginTop:"10px"}} label="Content" multiline rows="10" fullWidth variant="outlined" name="content" value={formState.content} onChange={e => handleChange(e)}/>
                    <input accept="image/*" id="icon-button-file" type="file" onChange={(evt) => handleImageUpload(evt)} />
                    <label htmlFor="icon-button-file">
                        <IconButton color="primary" aria-label="upload picture" component="span">
                        <PhotoCamera />
                        </IconButton>
                    </label>
                    <Button onClick={() => createPost(true)}>Add</Button> &nbsp; &nbsp; <Button onClick={() => createPost(false)}>Save</Button> 
                </form>
            </div>
        </div>

    )
}

export default BlogEditor;