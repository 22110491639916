import React, { useState, useEffect } from 'react';
import { Card, CardActionArea, CardContent, Typography, CardActions, Button } from "@material-ui/core";
import { NavLink } from 'react-router-dom';
import { makeStyles } from '@material-ui/core/styles';

import { DataStore } from "@aws-amplify/datastore";
import { Post, PostStatus } from "../models";

// style params for materiaul-ui cards
const useStyles = makeStyles({
    root: {
      maxWidth: 345,
    },
    media: {
      height: 140,
    },
    title: {
        fontSize: 14,
    }
});

const Blog = () => {

    const [posts, updatePosts] = useState([])

    const classes = useStyles();

    async function fetchPosts(){

        const posts = await DataStore.query(Post, post => post.status("eq", PostStatus.PUBLISHED)).catch(err => console.info(err));
        //console.info(posts);
        updatePosts(posts);
    }

    useEffect( () => {
        var isMounted = true
        if (isMounted){
            fetchPosts(); // on component load, fetch posts and update state. 
        }
        const subscription = DataStore.observe(Post).subscribe(() => {
            //console.info("MSG: ", msg.model, msg.opType, msg.element);
            fetchPosts();
        }); // makes it so that DataStore calls this callback function when an instance of Post changes on the backend
        return () => {
            subscription.unsubscribe();
            isMounted = false // this return function is a callback for when the component is unmounted. Therefore, set isMounted to false
        }
    }, [])

    return (
        <div className="bodyContainer">
            <h1>What's New?</h1>
            <hr />
            <article style={{marginLeft:"auto", marginRight:"auto", width:"65%", textAlign:"left"}}>
                <p>
                    Here is where I'll give my latest software-related updates, including things
                    I've recently learned, seen, or created... More soon to come!
                </p>
                <div>
                    {posts.map(post => {
                        return(
                            <Card className={classes.root} key={post.id} style={{margin:"0 auto", marginTop:"15px"}}>
                            <CardActionArea>
                                <CardContent>
                                <Typography className={classes.title} color="textSecondary" gutterBottom>
                                {post.date}
                                </Typography>
                                    <Typography gutterBottom variant="h5" component="h2">
                                        {post.title}
                                    </Typography>
                                    <Typography variant="body2" color="textSecondary" component="p">
                                        {post.content.substring(3,100) + "..."}
                                    </Typography>
                                </CardContent>
                            </CardActionArea>
                            <CardActions>
                                <Button size="small" color="primary">
                                    <NavLink exact to={"blogpost/" + post.id}>Read</NavLink>
                                </Button>
                            </CardActions>
                            </Card>
                        )
                    })}
                </div>
                
            </article>
        </div>
    )
}

export default Blog;