import React from "react";

const NotFound = () => {
  return (
    <div className="bodyContainer">
      <h1>Oops!</h1>
      <hr />
      <article
        style={{
          marginLeft: "auto",
          marginRight: "auto",
          width: "65%",
          textAlign: "center",
        }}
      >
        <p>
          It looks like the page you requested doesn't exist. Sorry about that!
        </p>
      </article>
    </div>
  );
};

export default NotFound;
