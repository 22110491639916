import React, { useEffect, useState } from 'react';
import { DataStore } from "@aws-amplify/datastore";
import { Post } from "../models";

const BlogPost = (props) => {

    const [post, updatePost] = useState({id:"", title:"", status:"",  content:"Placeholder", date:"", imgUrl:"#", imgKey:""})
    
    const {match : {params}} = props;
    const postID = params.postID;

    useEffect(() => {

        fetchPost(postID); // on component load, fetch posts and update state. 
        const subscription = DataStore.observe(Post).subscribe(() => fetchPost(postID)); // if online, schedule update of fetch posts once back online
        return () => subscription.unsubscribe()
    })

    async function fetchPost(id){ 
        // Fetch the post by ID that was passed in as a url parameter
        const post = await DataStore.query(Post, id);

        if (!post){
            updatePost({...post, title:"Whoops!", content:"It looks like the post you are requesting doesn't exist. Sorry for the inconvenience."})
        } else{

            updatePost(post);
        }
    }

    return (

        <div className="bodyContainer">
            <h1>{post.title}</h1>
            <h4 style={{color:"gray"}}>{post.date}</h4>
            <hr style={{width:"75%"}}></hr>
            <br/>
            <img className="blogImg" style={{ margin:"0 auto", width:"40%", height:"auto"}} src={post.imgUrl} alt="Post Header"/>
            <article style={{marginLeft:"auto", marginRight:"auto", width:"75%", textAlign:"left"}}>
                
                <div dangerouslySetInnerHTML={{__html: post.content}}>

                </div>
            </article>

        </div>
    )

}

export default BlogPost;