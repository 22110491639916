/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-2",
    "aws_cognito_identity_pool_id": "us-east-2:b21a5d16-2556-4caa-ba70-73526388bd90",
    "aws_cognito_region": "us-east-2",
    "aws_user_pools_id": "us-east-2_F0TpEnkBv",
    "aws_user_pools_web_client_id": "6c9g4ncec93khbmoodl6gca5gv",
    "oauth": {},
    "aws_appsync_graphqlEndpoint": "https://t5vej5slmraz7gxr6pp7svlh2a.appsync-api.us-east-2.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-2",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fhdwmjouurbcjohwvccco2uff4",
    "aws_user_files_s3_bucket": "blogimages190215-backend",
    "aws_user_files_s3_bucket_region": "us-east-2"
};


export default awsmobile;
